import { FC } from 'react'
import { useSelector } from 'react-redux'

import { getTagProps } from '@resources/dfaState'

import {
    DfaStatusEnum,
    EEarlyRepaymentStatus,
    IEarlyRepaymentFrontAsset,
    PriceSource,
    SettlementsType,
} from '@dltru/dfa-models'
import { Box, CaseBadge, Coins, Divider, EMPTY_FIELD_VALUE, Milestone, Tag } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'

import styles from './DfaInfo.m.less'
import { OwnerCell } from './OwnerCell'

interface IDfaInfoProps {
    application: IEarlyRepaymentFrontAsset
}

export const DfaInfo: FC<IDfaInfoProps> = ({ application }) => {
    const tagProps = getTagProps(application.asset.status)
    const paymentType = 'Лицевой счёт Владельца'
    const isPriceDynamic = application.asset.price_source_type === PriceSource.dynamic
    const uuid = useSelector(authSelector.selectUserUid)

    const inCaseView =
        (application.wallet_balance.active_amount_dfa ?? 0) +
        (application.wallet_balance.early_repayment_blocked_amount_dfa ?? 0)

    let originalAmount = application.asset.total_supply_invested ?? 0

    if (application.asset.status === 'redeemed' || application.asset.status === 'mature') {
        originalAmount = 0
    }

    return (
        <Box className={styles.container}>
            <Box direction="row" justify="left" className={styles.row}>
                <Box className={styles.title}>{application.asset.ticker_symbol}</Box>
                <a className={styles.titleLink} href={`/offers/${application.asset.id}`}>
                    {application.asset.title}
                </a>
                <Tag color={tagProps.color}>{tagProps.title}</Tag>
            </Box>

            <Box direction="column" className={styles.row}>
                <Box direction="row" align="center" justify="flex-start" className={styles.row}>
                    <span className={styles.label}>Эмитент</span>
                    <a
                        className={styles.userLink}
                        href={`/clients/${application.asset.emitter_id}`}
                    >
                        {application.asset.emitter_full_name ||
                            application.asset.emitter_info?.full_name ||
                            application.asset.emitter_info?.short_name ||
                            ''}
                    </a>
                </Box>
                <Box direction="row" align="center" justify="flex-start" className={styles.row}>
                    <span className={styles.label}>Владелец</span>

                    {application.request.user_uuid && (
                        <OwnerCell
                            ownerName={application.request.user_full_name}
                            ownerId={application.request.user_uuid}
                            bankDetails={
                                application.asset.status === DfaStatusEnum.emitment_success &&
                                application.asset.emitter_id === uuid &&
                                application.asset.issue_settlements_type ===
                                    SettlementsType.direct &&
                                (application.request.status === EEarlyRepaymentStatus.APPROVED ||
                                    application.request.status === EEarlyRepaymentStatus.PLACED)
                            }
                        />
                    )}
                </Box>
            </Box>

            <Divider />

            <Box direction="row" className={styles.detailContainer} justify="space-between">
                <Box className={styles.detailContainerLeft} direction="row">
                    <Box
                        direction="column"
                        className={styles.detail}
                        align="flex-start"
                        justify="space-between"
                    >
                        <span className={styles.label}>Цена погашения</span>
                        <h4 className={styles.money}>
                            {application.asset.redeem_price_per_dfa?.toLocaleString() ??
                                EMPTY_FIELD_VALUE}
                            <span className={styles.grayText}> ₽</span>
                        </h4>
                    </Box>

                    {application.asset.repayment_settlements_type === SettlementsType.direct && (
                        <div
                            className={styles.directStatusContainer}
                            style={{ marginLeft: '24px' }}
                        >
                            <Coins />
                            <div className={styles.directStatusLabel}>
                                Расчеты при погашении <br /> осуществляются вне Платформы
                            </div>
                        </div>
                    )}

                    {application.asset.repayment_settlements_type === SettlementsType.platform && (
                        <Box
                            direction="column"
                            className={styles.detail}
                            align="flex-start"
                            justify="space-between"
                        >
                            <span className={styles.label}>Сумма погашения будет зачислена на</span>
                            <Box className={styles.paymentType}>{paymentType}</Box>
                        </Box>
                    )}
                </Box>
                <Box className={styles.detailContainerRight} direction="row" justify="flex-end">
                    <Milestone
                        label="В обороте"
                        value={<span className={styles.borderBlock}>{originalAmount}</span>}
                        centered
                        withGap
                    />
                    {inCaseView && (
                        <Milestone
                            label="В кошельке"
                            value={<CaseBadge count={inCaseView ?? 0} buying={true} />}
                            centered
                            withGap
                        />
                    )}
                </Box>
            </Box>
            {isPriceDynamic && (
                <Box direction="row" className={styles.row}>
                    <p className={styles.dynamicPriceNote}>
                        Цена погашения 1 единицы ЦФА соответствует стоимости 1 грамма золота по цене
                        закрытия валютной пары GLD/RUB_TOM Московской биржи на дату, предшествующую
                        дате принятия настоящей заявки к исполнению.
                    </p>
                </Box>
            )}
        </Box>
    )
}
