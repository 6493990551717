import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC } from 'react'

interface IComponentProps {
    onOk: () => void
    onCancel: () => void
}

export const ConfirmByInvestorModal: FC<IComponentProps> = ({ onOk, onCancel }) => {

    return (
        <>
            <MiniModal
                visible={true}
                onCancel={onCancel}
                width={423}
                footer={[
                    <Button borderRadius={12} onClick={onCancel}>
                        Отмена
                    </Button>,
                    <Button
                        borderRadius={12}
                        type="primary"
                        onClick={onOk}
                    >
                        Да, подтверждаю
                    </Button>,
                ]}
            >
                <div>
                    <Box direction="row" align="center">
                        <Box className="modal-content-shift">
                            <QuestionCircle className="modal-icon modal-question-icon" />
                        </Box>
                        <div className="ant-modal-title">
                          Подтверждение досрочного погашения ЦФА
                        </div>
                    </Box>
                    <Box margin={[8, 40, 0, 40]}>
                      Вы действительно подтверждаете досрочное погашение ЦФА?
                    </Box>
                </div>
            </MiniModal>
        </>
    )
}
