import { Button, LabelRow, Outline, Price, RightMini, Row, Typography, Wallet } from '@dltru/dfa-ui'
import cn from 'classnames'
import { FC } from 'react'

import styles from './AccountLimitBlock.m.less'

interface IAccountLimitBlockProps {
    balance?: number
    account?: string
    error: boolean
    onClick: () => void
}

export const AccountLimitBlock: FC<IAccountLimitBlockProps> = ({
    balance,
    account,
    error,
    onClick,
}) => {
    return (
        <Outline className={cn(styles.walletWindget, { [`${styles.walletWindgetError}`]: error })}>
            <Row justify="space-between">
                {error ? (
                    <Typography.Text type="danger">Недостаточно средств на счёте</Typography.Text>
                ) : (
                    <Typography.Text type="secondary">Счёт</Typography.Text>
                )}

                <Button type="link" onClick={onClick} className="account-limit__small-button">
                    Пополнить <RightMini />
                </Button>
            </Row>
            <LabelRow
                label={
                    <>
                        <Wallet />
                        <span>{String(account)}</span>
                    </>
                }
                strong
            >
                <Price price={balance && balance / 100} error={error} />
            </LabelRow>
        </Outline>
    )
}
